
import {smoothScrollToTargetId} from "~/helpers/util";

export default {
  name: 'MoleculeAnchorV2',
  props: {
    title: {
      required: false,
      type: String,
      default: "",
    },
    subtitle: {
      required: false,
      type: String,
      default: "",
    },
    anchor: {
      required: false,
      type: String,
      default: 'javascript:void(0)'
    }
  },
  methods: {
    goToAnchor(anchor) {
      smoothScrollToTargetId(anchor)
    }
  }
}
