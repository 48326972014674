import { render, staticRenderFns } from "./MoleculeAnchorButtons.vue?vue&type=template&id=3332d384&"
import script from "./MoleculeAnchorButtons.vue?vue&type=script&lang=js&"
export * from "./MoleculeAnchorButtons.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeAnchorButtons.vue?vue&type=style&index=0&id=3332d384&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomButton: require('/app/components/atoms/common/AtomButton.vue').default})
