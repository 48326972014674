import { render, staticRenderFns } from "./MoleculeCustomers.vue?vue&type=template&id=ea205e9c&"
import script from "./MoleculeCustomers.vue?vue&type=script&lang=js&"
export * from "./MoleculeCustomers.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeCustomers.vue?vue&type=style&index=0&id=ea205e9c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomCustomer: require('/app/components/atoms/common/AtomCustomer.vue').default})
