
export default {
  name: 'MoleculeStatementsSlider',
  data() {
    return {
      instance: null,
      options: {
        perPage: 1,
        pagination: false,
        gap: '20px',
        arrows: true
      },
    }
  },
}
