

import {smoothScrollToTargetId} from "~/helpers/util";

export default {
  name: 'MoleculeAnchorButtons',
  props: {
    anchorButtons: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    goToNextSection(hash) {
      smoothScrollToTargetId(hash);
    },
  },
}
